<template>
  <div>
    <div class="row" style="margin-top:30px;" id="delPartLgn">
      <div id="content" class="col-md-12">
        <!-- 1st page ui -->
        <div class="page-login" v-if="showComp === 0">
          <div class="account-border">
            <h2>
              <b>
                Forgot Your Password?
              </b>
            </h2>
          </div>
          <div class="account-border">
            <p>
              We are here to help you to recover your account.
            </p>
          </div>
          <div style="margin-top: -12px;" class="account-border">
            <p>
              Enter your registered email or Mobile number to verify your
              account
            </p>
          </div>
          <div class="account-border">
            <img
              src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1630063951/cochchi/mercahnt/1630063949484.png"
              style="height: 300px;"
            />
          </div>
          <div class="account-border">
            <div class="form-group" style="width: 40%;">
              <label for="email">Email or Mobile No :</label>
              <input
                :style="changeBorder"
                v-model="value"
                @keyup="validateText(value)"
                type="text"
                class="form-control"
                id="text"
              />
              <small style="color: green;">Enter your mobile number in local number format. (07X XXX XXXX)</small>
            </div>
          </div>
          <div class="row" style="margin-bottom: 25px;">
            <div class="col-md-10 col-sm-10 col-lg-10">
              <button
                :disabled="dissableVal"
                @click="proceedClick"
                style="margin-top: 0px; width: 100px; float: right;"
                class="btn sign-reg-btn btn-block"
              >
                Proceed
              </button>
              <button
                @click="backToHome"
                style="margin-top: 0px; width: 135px; float: right; margin-right: 10px;"
                class="btn sign-reg-btn btn-block"
              >
                Back To Home
              </button>
            </div>
          </div>
        </div>
        <!-- 2nd page ui -->
        <div class="page-login" v-if="showComp === 1">
          <div class="account-border">
            <h2>
              <b>
                For verification your identity of your account,
              </b>
            </h2>
          </div>
          <div style="margin-top: -20px;" class="account-border">
            <h2>
              <b>
                please choose a way to verify
              </b>
            </h2>
          </div>
          <div
            style="margin-top: 25px; margin-bottom: 25px;"
            class="account-border"
          >
            <img
              src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1630064049/cochchi/mercahnt/1630064047542.png"
              style="height: 180px;"
            />
          </div>
          <div class="account-border" style="margin-bottom: 15px;">
            <button
              :disabled="dissableVal"
              @click="handleVerify('email')"
              style="margin-top: 0px; width: 205px; margin-right: 10px;"
              class="btn sign-reg-btn btn-block"
            >
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588786445/slt/portal_user/1588786444937.png"
                style="margin-right: 10px;"
              />
              Verify through Email
            </button>
            <button
              @click="handleVerify('sms')"
              style="margin-top: 0px; width: 205px;"
              class="btn sign-reg-btn btn-block"
            >
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588786403/slt/portal_user/1588786403027.png"
                style="margin-right: 10px;"
              />
              Verify through SMS
            </button>
          </div>
          <div
            style="margin-top: 20px; margin-bottom: 20px; text-decoration: underline;"
            class="account-border"
          >
            <a @click="backToHome">
              Back to home
            </a>
          </div>
        </div>
        <!-- email verify page ui -->
        <div class="page-login" v-if="showComp === 2">
          <div class="account-border">
            <img
              src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588789584/slt/portal_user/1588789584751.png"
            />
            <p style="font-size: 16px; margin-left: 35px; margin-right: -18px;">
              <b>
                We will send an one time code to your email
              </b>
            </p>
          </div>
          <div
            style="margin-top: -20px; margin-bottom: 25px;"
            class="account-border"
          >
            <p style="font-size: 14px;">
              Click send code to receive your code
            </p>
          </div>
          <div class="account-border" style="margin-bottom: 15px;">
            <input
              disabled
              style="width: 30%; margin-left: 15px;"
              v-model="validateEmail"
              type="text"
              class="form-control"
              id="text"
            />
            <button
              v-if="resend === 0"
              @click="handleSendCode(1)"
              style="margin-top: 0px; width: 160px; margin-left: 15px; color: black; background: #ffd338; border: 1px solid #ffd338; font-size: 14px;"
            >
              Send Code
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588797595/slt/portal_user/1588797595469.png"
                style="margin-left: 10px;"
              />
            </button>
            <button
              disabled
              v-if="resend === 1"
              style="margin-top: 0px; width: 160px; margin-left: 15px; color: rgb(120, 120, 120); background: white; border: 1px solid rgb(120, 120, 120); font-size: 14px;"
            >
              Resend ( {{ countdownseconds }} )
            </button>
          </div>
          <div
            style="margin-bottom: 25px; margin-top: 35px;"
            class="account-border"
          >
            <p>
              Enter received one time code here
            </p>
          </div>
          <div class="account-border" style="margin-bottom: 35px;">
            <p style="margin-right: -100px; margin-top: -25px;">
              Enter Code
            </p>
            <input
              style="width: 52%; margin-left: 35px;"
              v-model="code"
              type="text"
              class="form-control"
              id="text"
            />
          </div>
          <div class="account-border" style="margin-bottom: 15px;">
            <button
              :disabled="dissable"
              @click="handleBack()"
              style="margin-top: 0px; width: 320px; margin-right: 10px;  color: #ffd338; background: white; border: 1px solid #ffd338; font-size: 14px;"
            >
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588795975/slt/portal_user/1588795975720.png"
                style="margin-right: 10px; transform: rotate(180deg);"
              />
              Check other ways to verify account
            </button>
            <button
              :disabled="dissable"
              @click="handleVerifySendCode()"
              style="margin-top: 0px; width: 120px;"
              class="btn sign-reg-btn btn-block"
            >
              Verify
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588797595/slt/portal_user/1588797595469.png"
                style="margin-left: 10px;"
              />
            </button>
          </div>
          <div
            style="margin-top: 20px; margin-bottom: 20px; text-decoration: underline;"
            class="account-border"
          >
            <a @click="backToHome">
              Back to home
            </a>
          </div>
        </div>
        <!-- mobile verify page ui -->
        <div class="page-login" v-if="showComp === 3" style="margin-top: 30px;">
          <div class="account-border">
            <img
              src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1630064458/cochchi/mercahnt/1630064458216.png"
            />
            <p style="font-size: 16px; margin-left: 35px; margin-right: -18px;">
              <b>
                We will send an one time code to your Mobile number
              </b>
            </p>
          </div>
          <div
            style="margin-top: -20px; margin-bottom: 25px;"
            class="account-border"
          >
            <p style="font-size: 14px; margin-right: 88px;">
              Click send code to receive your code
            </p>
          </div>
          <div class="account-border" style="margin-bottom: 15px;">
            <input
              disabled
              style="width: 30%; margin-left: 15px;"
              v-model="validateMobile"
              type="text"
              class="form-control"
              id="text"
            />
            <button
              v-if="resend === 0"
              @click="handleSendCode(2)"
              style="margin-top: 0px; width: 160px; margin-left: 15px; color: black; background: #ffd338; border: 1px solid #ffd338; font-size: 14px;"
            >
              Send Code
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588797595/slt/portal_user/1588797595469.png"
                style="margin-left: 10px;"
              />
            </button>
            <button
              disabled
              v-if="resend === 1"
              style="margin-top: 0px; width: 160px; margin-left: 15px; color: rgb(120, 120, 120); background: white; border: 1px solid rgb(120, 120, 120); font-size: 14px;"
            >
              Resend ( {{ countdownseconds }} )
            </button>
          </div>
          <div
            style="margin-bottom: 25px; margin-top: 35px;"
            class="account-border"
          >
            <p>
              Enter received one time code here
            </p>
          </div>
          <div class="account-border" style="margin-bottom: 35px;">
            <p style="margin-right: -100px; margin-top: -25px;">
              Enter Code
            </p>
            <input
              style="width: 52%; margin-left: 35px;"
              v-model="code"
              type="text"
              class="form-control"
              id="text"
            />
          </div>
          <div class="account-border" style="margin-bottom: 15px;">
            <button
              :disabled="dissable"
              @click="handleBack()"
              style="margin-top: 0px; width: 320px; margin-right: 10px;  color: #ffd338; background: white; border: 1px solid #ffd338; font-size: 14px;"
            >
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588795975/slt/portal_user/1588795975720.png"
                style="margin-right: 10px; transform: rotate(180deg);"
              />
              Check other ways to verify account
            </button>
            <button
              :disabled="dissable"
              @click="handleVerifySendCode()"
              style="margin-top: 0px; width: 120px;"
              class="btn sign-reg-btn btn-block"
            >
              Verify
              <img
                src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588797595/slt/portal_user/1588797595469.png"
                style="margin-left: 10px;"
              />
            </button>
          </div>
          <div
            style="margin-top: 20px; margin-bottom: 20px; text-decoration: underline;"
            class="account-border"
          >
            <a @click="backToHome">
              Back to home
            </a>
          </div>
        </div>
        <!-- reset password page ui -->
        <div class="page-login" v-if="showComp === 4">
          <div
            style="margin-bottom: 25px; margin-top: 35px;"
            class="account-border"
          >
            <img
              src="http://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1588799019/slt/portal_user/1588799019876.png"
              style="margin-bottom: 30px; margin-right: 10px; margin-top: -15px;"
            />
            <p style="font-size: 20px; margin-left: 23px; margin-right: 35px;">
              <b>
                Please enter your new password here.
              </b>
            </p>
          </div>
          <div class="row">
            <div class="account-border">
              <p style="margin-right: -124px; margin-top: -25px;">
                New Password
              </p>
              <input
                @input="valNewPassword()"
                oncopy="return false"
                onpaste="return false"
                style="width: 52%; margin-left: 35px;"
                v-model="password"
                type="password"
                ref="inputLgnPwd"
                class="form-control"
                id="text"
              />
              <span @click="togglePwd()" ref="toggleIcon"
                ><i
                  style="position: relative;bottom: -10px;right: 25px;"
                  class="fa fa-eye"
                ></i
              ></span>
              <!-- <span @click="togglePwd()" ref="toggleIcon"><i style="position: absolute; top: 135px; right: 250px;" class="fa fa-eye"></i></span> -->
            </div>
            <div
              v-show="!newPasswordValid && password != ''"
              style="    display: flex;
                                    position: relative;
                                    margin-bottom: 20px;
                                    align-items: center;
                                    justify-content: center;"
            >
              <span style="color: grey;font-size:10px;">
                <span
                  v-for="(vrule, index) in valRuleArrayNew"
                  :key="vrule.index"
                >
                  {{ vrule.valRule }} <span v-html="vrule.valIcon"></span
                  >{{ valRuleArrayConf.length - 1 != index ? ", " : "" }}
                </span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="account-border" style=" margin-top:30px;">
              <p style="margin-right: -178px; margin-top: -25px;">
                Confirm New Password
              </p>
              <input
                @input="valConfPassword()"
                oncopy="return false"
                onpaste="return false"
                style="width: 52%; margin-left: 35px;"
                v-model="conPassword"
                type="password"
                ref="inputLgnPwd1"
                class="form-control"
                id="text"
              />
              <span @click="togglePwd1()" ref="toggleIcon1"
                ><i
                  style="position: relative;bottom: -10px;right: 25px;"
                  class="fa fa-eye"
                ></i
              ></span>
              <!-- <span @click="togglePwd1()" ref="toggleIcon1"><i style="position: absolute; top: 205px; right: 246px;" class="fa fa-eye"></i></span> -->
            </div>
            <div
              v-show="!confPasswordValid && conPassword != ''"
              style="    display: flex;
                                    position: relative;
                                    margin-bottom: 20px;
                                    align-items: center;
                                    justify-content: center;"
            >
              <span style="color: grey;font-size:10px;">
                <span
                  v-for="(vrule, index) in valRuleArrayConf"
                  :key="vrule.index"
                >
                  {{ vrule.valRule }}
                  <span
                    v-html="vrule.valIcon"
                    style="color: grey;font-size:10px;"
                  ></span
                  >{{ valRuleArrayConf.length - 1 != index ? ", " : "" }}
                </span>
              </span>
            </div>
          </div>
          <div class="account-border" style="margin-top:30px;">
            <button
              @click="submit()"
              :disabled="!formIsValid"
              style="margin-top: 0px; width: 100px;"
              class="btn sign-reg-btn btn-block"
            >
              Submit
            </button>
          </div>
          <div
            style="margin-top: 20px; margin-bottom: 20px; text-decoration: underline;"
            class="account-border"
          >
            <a @click="backToHome">
              Back to home
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APIResetPassWord from "@/mixins/merchant/APIResetPassWord";
export default {
  mixins: [APIResetPassWord],
  data() {
    return {
      value: "",
      email: "",
      limit: 90,
      mobile: "",
      confPasswordValid: false,
      newPasswordValid: false,
      validateMobile: "",
      validateEmail: "",
      code: "",
      password: "",
      conPassword: "",
      resend: 0,
      countDown: 10,
      countdownseconds: 0,
      dissableVal: true,
      dissable: false,
      showComp: 0, // 0 - first page, 1 - second page, 2 - email verify page, 3 - sms verify page, 4 - reset password page
      changeBorder: {
        border: "1px solid #ccc",
      },
      valRuleArrayNew: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: 'One numerical character',
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
        }
      ],
      valRuleArrayConf: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "Match password",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: 'One numerical character',
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
        }
      ],
    };
  },
  computed: {
    formIsValid() {
      this.valConfPassword();
      return this.newPasswordValid && this.confPasswordValid;
    },
  },
  watch: {
    countdownseconds: function(val) {
      if (val === -1) {
        this.resend = 0;
      }
    },
  },
  created() {
    this.countdownCal(this.limit);
  },
  methods: {
    valNewPassword: function() {
      var pattOne = /(?=.*[a-z])/;
      var pattTwo = /(?=.*[A-Z])/;
      var pattThree = /(?=.*\W)/;
      var pattFour = /[0-9]/;
      // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

      if (pattOne.test(this.password) == true) {
        this.valRuleArrayNew[1].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[1].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattTwo.test(this.password) == true) {
        this.valRuleArrayNew[2].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[2].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattThree.test(this.password) == true) {
        this.valRuleArrayNew[3].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[3].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattFour.test(this.password) == true) {
        this.valRuleArrayNew[4].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[4].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (this.password.length >= 8 == true) {
        this.valRuleArrayNew[0].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[0].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (
        pattOne.test(this.password) == true &&
        pattTwo.test(this.password) == true &&
        pattThree.test(this.password) == true &&
        pattFour.test(this.password) == true &&
        this.password.length >= 8
      ) {
        this.newPasswordValid = true;
      } else {
        this.newPasswordValid = false;
      }
    },
    valConfPassword: function() {
      var pattOne = /(?=.*[a-z])/;
      var pattTwo = /(?=.*[A-Z])/;
      var pattThree = /(?=.*\W)/;
      var pattFour = /[0-9]/;
      // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

      if (pattOne.test(this.conPassword) == true) {
        this.valRuleArrayConf[1].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[1].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattTwo.test(this.conPassword) == true) {
        this.valRuleArrayConf[2].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[2].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattThree.test(this.conPassword) == true) {
        this.valRuleArrayConf[3].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[3].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattFour.test(this.conPassword) == true) {
        this.valRuleArrayConf[5].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[5].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (this.conPassword.length >= 8 == true) {
        this.valRuleArrayConf[0].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[0].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (this.password === this.conPassword) {
        this.valRuleArrayConf[4].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[4].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (
        pattOne.test(this.conPassword) == true &&
        pattTwo.test(this.conPassword) == true &&
        pattThree.test(this.conPassword) == true &&
        pattFour.test(this.conPassword) == true &&
        this.conPassword.length >= 8 &&
        this.password == this.conPassword
      ) {
        this.confPasswordValid = true;
      } else {
        this.confPasswordValid = false;
      }
    },
    togglePwd: function() {
      if (this.$refs.inputLgnPwd.type === "password") {
        this.$refs.inputLgnPwd.type = "text";
        this.$refs.toggleIcon.innerHTML = `<i style="position: relative; bottom: -10px; right: 25px;" class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputLgnPwd.type = "password";
        this.$refs.toggleIcon.innerHTML = `<i style="position: relative; bottom: -10px; right: 25px;" class="fa fa-eye"></i>`;
      }
    },
    togglePwd1: function() {
      if (this.$refs.inputLgnPwd1.type === "password") {
        this.$refs.inputLgnPwd1.type = "text";
        this.$refs.toggleIcon1.innerHTML = `<i style="position: relative; bottom: -10px; right: 25px;" class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputLgnPwd1.type = "password";
        this.$refs.toggleIcon1.innerHTML = `<i style="position: relative; bottom: -10px; right: 25px;" class="fa fa-eye"></i>`;
      }
    },
    countdownCal(duration) {
      this.countdownseconds = duration;
      let self = this;
      var downloadTimer = setInterval(function() {
        self.countdownseconds -= 1;
        if (self.countdownseconds <= 0) {
          clearInterval(downloadTimer);
        }
      }, 1000);
    },
    backToHome() {
      this.value = "";
      this.email = "";
      this.mobile = "";
      this.code = "";
      this.validateMobile = "";
      this.validateEmail = "";
      this.showComp = 0;
      this.$router.push("/merchant-landing-new") ;
    },
    proceedClick: async function() {
      try {
        this.dissableVal = true;
        let obj = {};
        obj.username = this.value;
        this.response = await this.checkUser(obj);
        this.email = this.response.email;
        this.mobile = this.response.mobile;
        this.showComp = 1;
        this.value = "";
        this.dissableVal = false;
      } catch (error) {
        this.dissableVal = true;
        this.value = "";
        this.showComp = 0;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Could not get details!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    handleSendCode: async function(value) {
      try {
        this.countdownCal(this.limit);
        let obj1 = {};
        obj1._id = this.response._id;
        obj1.username = value === 1 ? this.email : this.mobile;
        this.resend = 1;
        this.dissable = true;
        let res = await this.forgetPasswordOtp(obj1);
        this.dissable = false;
      } catch (error) {
        this.resend = 1;
        this.dissable = true;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Could not get details!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    handleVerify(str) {
      if (str === "email") {
        this.validateEmail = "";
        let val = this.email.split("@");
        let val1 = val[0][0] + val[0][1];
        let val2 = "@" + val[1];
        this.validateEmail = val1 + "****" + val2;
        this.showComp = 2;
        this.limit = 90;
        this.resend = 0;
      } else if (str === "sms") {
        this.validateMobile = "";
        let length = this.mobile.length;
        this.validateMobile =
          "****" +
          this.mobile[length - 3] +
          this.mobile[length - 2] +
          this.mobile[length - 1];
        this.showComp = 3;
        this.limit = 90;
        this.resend = 0;
      }
    },
    handleVerifySendCode: async function() {
      try {
        let obj2 = {};
        obj2._id = this.response._id;
        obj2.code = this.code;
        let res = await this.forgetPasswordOtpVerify(obj2);
        this.code = "";
        this.showComp = 4;
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Could not get details!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    handleBack() {
      this.code = "";
      this.showComp = 1;
    },
    submit: async function() {
      try {
        let obj3 = {};
        obj3._id = this.response._id;
        obj3.password = this.password;
        // let response = await this.resetPassword(obj3);
        let response = await this.setMearchatPassword(obj3);
        // console.log(response);
        // localStorage.setItem("merchant_token", response.token);
        // localStorage.setItem("merchant_type", "merchant");
        // localStorage.setItem("login_merchant", response._id);
        // localStorage.setItem("merchant_data", JSON.stringify(response));
        // location.reloard();
        this.password = "";
        this.conPassword = "";
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "success change password",
          showConfirmButton: false,
          timer: 1500,
        });
        
        this.$router.push("/merchant-landing-new");
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Cannot use previous password",
          showConfirmButton: false,
          timer: 1500,
        });
        this.password = "";
        this.conPassword = "";
      }
      //   this.$router.push("/merchant-order-group") ;
    },
    validateText(val) {
      var mobile = /^\d{10}$/;
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email.test(String(val))) {
        this.changeBorder.border = "1px solid green";
        this.dissableVal = false;
      } else {
        if (val.match(mobile)) {
          this.changeBorder.border = "1px solid green";
          this.dissableVal = false;
        } else {
          this.changeBorder.border = "1px solid red";
          this.dissableVal = true;
        }
      }
      if (val === "") {
        this.changeBorder.border = "1px solid #ccc";
        this.dissableVal = true;
      }
    },
  },
};
</script>
<style>
.sign-reg-btn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.sign-reg-btn:hover {
  background: #000000;
  color: #ffffff;
}

.account-border {
  display: flex;
  flex: 1;
  justify-content: center;
}
#delPartLgn .input-password input[type="password"] {
  position: relative;
}
#delPartLgn .input-password span.toggleIcon {
  position: relative;
  display: block;
  float: right;
  top: -31px;
  right: 7px;
  cursor: pointer;
}
#delPartLgn .input-password .fa {
  font-size: 15px;
}
.delprof-sbtn {
  /* width: 100%; */
  background: #ffd338 !important;
  color: #000000 !important;
  border: none !important;
}
.delprof-sbtn:hover {
  color: #ffffff !important;
}
.del-lgn-btn {
  width: 100%;
}
@media (min-width: 768px) {
  .loginPara {
    margin-bottom: 218px;
  }
}
@media (max-width: 768px) {
  .btnGoogle {
    margin-bottom: 5px;
    margin-top: -4px;
  }
  .btnFB {
    margin-bottom: -2px;
  }
}
@media (min-width: 1280px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1440px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1880px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1920px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 2880px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (max-width: 425px) {
  .firstCardCustomer {
    margin-bottom: 15px;
  }
  .btnGoogle {
    margin-bottom: 10px;
  }
}
.login-google {
  font-size: 29px;
  margin-left: 0px;
  margin-right: 15px;
}
.btn-facebook {
  background-color: #29487d !important;
  border-color: #29487d;
  display: inline-flex;
}
.btn-facebook:active,
._4jy1._42fs {
  background-color: #29487d;
  border-color: #29487d;
}

.btn-google {
  background-color: #d9534f !important;
  border-color: #29487d;
  display: inline-flex;
}
.btn-google:hover {
  background-color: #cc514e !important;
  border-color: #ffff !important;
}
.btn-facebook:hover {
  background-color: #365899 !important;
  border-color: #365899 !important;
}
.align-end {
  display: flex;
  align-items: center;
  justify-content: center;
}
.supiripola_delivery_logo {
  width: 246px;
  height: 64px;
  object-fit: contain;
}
.content-right {
  text-align: center;
  margin-bottom: 20px;
}
.row-submit-btn {
  margin-top: 40px;
}
.rect-right {
  background-color: rgb(242, 242, 242);
  padding: 30px;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.signup-link {
  color: #ffd300;
  text-decoration: underline !important;
}
/* .signup-link:hover{
    color:#c8a21b; 
    text-decoration: underline!important;   
} */
</style>
